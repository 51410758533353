import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import  StoreContext  from '../context/StoreContext';
import Search from '../components/Search';

function SearchPage(props) {
  const queryParams = new URLSearchParams(props.location.search);
  const search = queryParams.get(`q`);
  const changeUrlParams = (params) => {
    props.navigate(`?q=${params}`)
  }
  const { site, siteSearchIndex } = useStaticQuery(graphql`
    query SearchQuery {
      siteSearchIndex {
        index
      }
    }
  `);
  return (
    <section className="w-full">
      <SEO
        keywords={[`search`]}
        title="Busca"
      />

      <StoreContext.Consumer>
        {context => {
          if (!context.state) {
            return <></>
        }
        return (
          <>

          <div>
            <Search
              addToCart={(id, values) => context.addProductToCart( id, values)}
              changeUrlParams={changeUrlParams}
              defaulValue={search}
              searchIndex={siteSearchIndex.index}
              />
          </div>

          </>)
        }}
      </StoreContext.Consumer>



    </section>
  );
}

export default SearchPage;
